<template>
    <div style="background: linear-gradient(45deg, #FFE600 0%, #F90 100%);">
        <div>
            <headerComponent></headerComponent>
        </div>
        <div class="thankYouHeaderGrid">
            <div class="thankYou_headerGridRow_1">
                <svg xmlns="http://www.w3.org/2000/svg" width="196" height="148" viewBox="0 0 196 148" fill="none">
                    <g filter="url(#filter0_d_672_10684)">
                        <rect x="64" y="80" width="16" height="16" fill="#02090B"/>
                        <rect x="48" y="64" width="16" height="16" fill="#02090B"/>
                        <rect x="16" y="48" width="32" height="16" fill="#02090B"/>
                        <rect y="64" width="16" height="16" fill="#02090B"/>
                        <rect x="80" y="64" width="16" height="16" fill="#02090B"/>
                        <rect x="96" y="48" width="16" height="16" fill="#02090B"/>
                        <rect x="112" y="32" width="16" height="16" fill="#02090B"/>
                        <rect x="128" y="16" width="16" height="16" fill="#02090B"/>
                        <rect x="144" width="32" height="16" fill="#02090B"/>
                        <rect x="64" y="128" width="16" height="16" fill="#02090B"/>
                        <rect x="80" y="112" width="16" height="16" fill="#02090B"/>
                        <rect x="128" y="64" width="16" height="16" fill="#02090B"/>
                        <rect x="96" y="96" width="16" height="16" fill="#02090B"/>
                        <rect x="144" y="48" width="16" height="16" fill="#02090B"/>
                        <rect x="112" y="80" width="16" height="16" fill="#02090B"/>
                        <rect x="160" y="32" width="16" height="16" fill="#02090B"/>
                        <rect x="176" y="16" width="16" height="16" fill="#02090B"/>
                        <rect x="32" y="96" width="16" height="16" fill="#02090B"/>
                        <rect x="16" y="80" width="16" height="16" fill="#02090B"/>
                        <rect x="48" y="112" width="16" height="16" fill="#02090B"/>
                        <path d="M176 16H144V32H128V48H112V64H96V80H80V96H64V80H48V64H16V80H32V96H48V112H64V128H80V112H96V96H112V80H128V64H144V48H160V32H176V16Z" fill="url(#paint0_linear_672_10684)"/>
                    </g>
                    <defs>
                        <filter id="filter0_d_672_10684" x="0" y="0" width="196" height="148" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dx="4" dy="4"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_672_10684"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_672_10684" result="shape"/>
                        </filter>
                        <linearGradient id="paint0_linear_672_10684" x1="173.756" y1="18.1206" x2="96.6981" y2="143.57" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFE600"/>
                            <stop offset="0.479167" stop-color="#23EE5C"/>
                            <stop offset="1" stop-color="#03DAC6"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div class="thankYou_headerGridRow_2"><h1 class="tywelcome" style="color: #000000;text-align: center">We've got your e-mail!</h1></div>
            <div class="thankYou_headerGridRow_3"> A Monoversian will contact you soon</div>
            <div  class="thankYou_headerGridRow_4">
                <div class="exploreBtn">
                    <a href="/"><div class="headerJoinBtnMain" style="width: 177px; height: 48px;margin-bottom: 96px;margin-top: 32px" ><p class="headerMoreBtntext">Back to Monoverse</p></div></a>
                    <!--                    <a href="/join"><div class="headerMoreBtnMain" ><p class="headerJoinBtntext">Discover more</p></div></a>-->
                </div>
            </div>
        </div>
<!--        <div>-->
<!--            <nextStepsG></nextStepsG>-->
<!--        </div>-->
        <div class="tyexploreDomains" >
            <h1 style="color:#000;">Explore our domain collection</h1>
        </div>
        <div>
            <DomainsGrid></DomainsGrid>
        </div>

        <div>
            <div class="exploreBtn">
                <a href="/domains"><div class="headerJoinBtnMain" style="width: 177px; height: 48px;margin-bottom: 128px;margin-top: 32px" ><p class="headerMoreBtntext">Explore More</p></div></a>
                <!--                    <a href="/join"><div class="headerMoreBtnMain" ><p class="headerJoinBtntext">Discover more</p></div></a>-->
            </div>
        </div>

        <div>
            <mainfooter></mainfooter>
        </div>

    </div>
</template>

<script>
    import headerComponent from "@/components/reusable/header";
    import mainfooter from "@/components/footer";
    import DomainsGrid from "@/components/reusable/DomainsGrid";
    import nextStepsG from "@/components/reusable/nextSteps";

    export default {
        name: "thankYouMail",
        components: {headerComponent,mainfooter,DomainsGrid,nextStepsG},
        data: () => ({
            show:false,
        }),
        mounted() {
            this.show = true; // might need this.$nextTick
        },

    }
</script>

<style>


</style>


