<template>
    <div>
    <div class="nextSteps">
        <h2>Next steps after completing your domain purchase</h2>
    </div>
    <div>
        <div class="nextStepsGrid">
            <div class="nextStepsGrid_1">
                <div class="nextStepsGrid_icon">
                    <svg class="svgImgNextSteps" xmlns="http://www.w3.org/2000/svg" width="126" height="81" viewBox="0 0 126 81" fill="none">
                        <rect x="9" y="9" width="54" height="63" fill="url(#paint0_linear_648_9572)"/>
                        <rect x="18" y="45" width="9" height="9" fill="black"/>
                        <rect x="54" y="54" width="9" height="9" fill="black"/>
                        <rect x="63" y="54" width="9" height="9" fill="black"/>
                        <rect x="27" y="18" width="18" height="9" fill="black"/>
                        <rect x="9" width="54" height="9" fill="black"/>
                        <rect x="9" y="72" width="45" height="9" fill="black"/>
                        <rect x="27" y="36" width="18" height="9" fill="black"/>
                        <rect x="27" y="54" width="18" height="9" fill="black"/>
                        <rect x="45" y="27" width="9" height="27" fill="black"/>
                        <rect x="63" y="9" width="9" height="45" fill="black"/>
                        <rect y="9" width="9" height="63" fill="black"/>
                        <rect x="90" y="45" width="9" height="9" fill="#FFE600"/>
                        <rect x="99" y="36" width="9" height="9" fill="#FFE600"/>
                        <rect x="108" y="27" width="9" height="9" fill="#FFE600"/>
                        <rect x="117" y="18" width="9" height="9" fill="#FFE600"/>
                        <rect x="54" y="63" width="9" height="9" fill="#FFE600"/>
                        <rect x="45" y="54" width="9" height="9" fill="#FFE600"/>
                        <rect x="63" y="72" width="9" height="9" fill="#FFE600"/>
                        <rect x="72" y="63" width="9" height="9" fill="#FFE600"/>
                        <rect x="81" y="54" width="9" height="9" fill="#FFE600"/>
                        <defs>
                            <linearGradient id="paint0_linear_648_9572" x1="62.129" y1="10.0603" x2="-0.3222" y2="63.835" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#03DAC6"/>
                                <stop offset="0.47377" stop-color="#5091E6"/>
                                <stop offset="1" stop-color="#812CC8"/>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div class="nextStepsGrid_text">
                    After completing payment we will need to verify the email which is connected with your payment.
                </div>
            </div>
            <div class="nextStepsGrid_2">
                <div class="nextStepsGrid_icon">
                    <svg class="svgImgNextSteps" xmlns="http://www.w3.org/2000/svg" width="99" height="81" viewBox="0 0 99 81" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M63 9H36V27H63V9ZM90 36H9V72H90V36Z" fill="url(#paint0_linear_648_9523)"/>
                        <rect x="63" y="9" width="9" height="18" fill="black"/>
                        <rect x="27" y="9" width="9" height="18" fill="black"/>
                        <rect x="36" width="27" height="9" fill="black"/>
                        <rect x="9" y="27" width="81" height="9" fill="black"/>
                        <rect x="9" y="72" width="81" height="9" fill="black"/>
                        <rect y="36" width="9" height="36" fill="black"/>
                        <rect x="90" y="36" width="9" height="36" fill="black"/>
                        <rect x="44.4297" y="41" width="15.2143" height="5.2" fill="#FFE600"/>
                        <rect x="19.0703" y="41" width="15.2143" height="5.2" fill="#FFE600"/>
                        <rect x="19.0703" y="51.4004" width="15.2143" height="5.2" fill="#FFE600"/>
                        <rect x="19.0703" y="61.8008" width="15.2143" height="5.2" fill="#FFE600"/>
                        <rect x="69.7812" y="41" width="15.2143" height="5.2" fill="#FFE600"/>
                        <rect x="44.4297" y="51.4004" width="15.2143" height="5.2" fill="#FFE600"/>
                        <rect x="64.7188" y="51.4004" width="20.2857" height="5.2" fill="#FFE600"/>
                        <rect x="54.5703" y="46.1992" width="5.07143" height="5.2" fill="#FFE600"/>
                        <rect x="79.9297" y="46.1992" width="5.07143" height="5.2" fill="#FFE600"/>
                        <rect x="39.3594" y="46.1992" width="5.07143" height="20.8" fill="#FFE600"/>
                        <rect x="14" y="46.1992" width="5.07143" height="20.8" fill="#FFE600"/>
                        <rect x="64.7188" y="46.1992" width="5.07143" height="20.8" fill="#FFE600"/>
                        <defs>
                            <linearGradient id="paint0_linear_648_9523" x1="88.6935" y1="10.0603" x2="27.5548" y2="89.0271" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#03DAC6"/>
                                <stop offset="0.47377" stop-color="#5091E6"/>
                                <stop offset="1" stop-color="#812CC8"/>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div class="nextStepsGrid_text">
                    We will send you the Transfer Authorization Code ( EPP ) to your email.
                </div>

            </div>
            <div class="nextStepsGrid_3">
                <div class="nextStepsGrid_icon">
                    <svg class="svgImgNextSteps" xmlns="http://www.w3.org/2000/svg" width="108" height="81" viewBox="0 0 108 81" fill="none">
                        <rect x="27" y="18" width="9" height="9" transform="rotate(180 27 18)" fill="black"/>
                        <rect x="18" y="27" width="9" height="9" transform="rotate(180 18 27)" fill="black"/>
                        <rect x="9" y="45" width="9" height="18" transform="rotate(180 9 45)" fill="black"/>
                        <rect x="99" y="36" width="9" height="18" fill="black"/>
                        <rect x="90" y="54" width="9" height="9" fill="black"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M27 9H81V45H90V36H99V54H90V63H81V72H27V36H18V45H9V27H18V18H27V9Z" fill="url(#paint0_linear_648_9547)"/>
                        <rect x="81" y="63" width="9" height="9" fill="black"/>
                        <rect x="72" y="9" width="45" height="9" transform="rotate(180 72 9)" fill="black"/>
                        <rect x="36" y="72" width="45" height="9" fill="black"/>
                        <rect x="81" y="18" width="9" height="27" fill="#FFE600"/>
                        <rect x="72" y="9" width="9" height="45" fill="#FFE600"/>
                        <rect x="45" y="27" width="54" height="9" fill="#FFE600"/>
                        <rect x="27" y="63" width="9" height="27" transform="rotate(180 27 63)" fill="#FFE600"/>
                        <rect x="36" y="72" width="9" height="45" transform="rotate(180 36 72)" fill="#FFE600"/>
                        <rect x="63" y="54" width="54" height="9" transform="rotate(180 63 54)" fill="#FFE600"/>
                        <defs>
                            <linearGradient id="paint0_linear_648_9547" x1="97.5484" y1="10.0603" x2="40.0947" y2="91.7807" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#03DAC6"/>
                                <stop offset="0.47377" stop-color="#5091E6"/>
                                <stop offset="1" stop-color="#812CC8"/>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div class="nextStepsGrid_text">
                    Complete the transfer process with the Domain Name Registrar of your choice.
                </div>

            </div>
        </div>
    </div>

    </div>
</template>

<script>
export default {
name: "nextStepsG",
}
</script>

<style>


</style>

